<template>
    <div class="flex column center" style="margin-bottom:4rem;">
        <div class="new_box">
            <div class="titlee">历思战略合作单位——青岛中院领导莅临福建历思司法鉴定所调研指导！</div>
            <p class="time">2021-11-04</p>
            <p class="space_content">10月27日，历思战略合作单位——青岛市中级人民法院领导到福建历思司法鉴定所进行调研指导。座谈会上，历思负责人介绍了2020年以来受理的青岛法院司法鉴定案件的概况。双方就司法鉴定案件中存在的难点问题及下一步更深入的合作进行交流讨论。</p>
            <div class="col8" style="margin:2rem auto">
                <img class="col8" v-lazy="require('../../assets/news/ls/1-1.png')">
            </div>
            <p class="space_content">
                历思是“山东省青岛市中级人民法院司法鉴定战略合作实践基地”。自2019年双方签订司法鉴定战略合作协议以来，历思对青岛中院委托的案件开通绿色通道，高效率、高质量完成鉴定工作，为审判工作提供更好的服务，合作取得了良好的成效。
        
            </p>
            
            
        </div>
        <div class="flex wrap col442" style="width:100%;text-align:left;margin-top:1rem">
            <div style="margin-bottom:1rem" class="col4 flex  start1">
                <div class="flex" style="margin-right:10%">
                    <p style="white-space:nowrap">上一篇：</p>无
                    
                </div>
            </div>
            <div style="margin-bottom:1rem" class="col4 flex  start1">
                <div class="flex">
                    <p style="white-space:nowrap">下一篇：</p>
                    <router-link class="a" :to="'/news/lsnews/2'"><p class="ellipsis1">庆贺！我所曾建晟主任当选厦门市司法鉴定协会监事长~</p></router-link>
                    
                </div>
            </div>
            <div class="col2 flex  end1 center">
                <router-link class="a" style="color:#fff" :to="'/news/lsnews'"><el-button type="primary">返回列表</el-button></router-link >
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return { 
            data:{

            }
        }
    },
}
</script>